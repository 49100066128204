module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Erin McGrath: Front-end Developer","short_name":"Erin McGrath","start_url":"/","background_color":"#701dc9","theme_color":"#701dc9","display":"minimal-ui","icon":"src/images/female-technologist-favicon.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
